<template>
  <div class="check-plan">
    <nav-bar />
    <div class="content-body">
      <left-bar ref="leftBar" @check-item-deploy="checkItemDeploy" :listCurrent="listCurrent" />
      <div class="bottom-style">
        <top-hint ref="topHint" v-if="!currentData.dirPage && ![1].includes(+currentData.dirType)" :currentData="currentData" :fireData="fireData" />

        <div class="right-center-map">
          <!-- 需要截图的部分 -->
          <div id="rightCenterMap">
            <edit-title ref="editTitle" v-if="+currentData.dirType === 1" :currentData="currentData" />

            <deduce-data v-else-if="+currentData.dirType === 5 && !currentData.dirPage" />

            <power-transfer v-else-if="+currentData.dirType === 3 && +currentData.scene === 0" />

            <dispatch v-if="+currentData.dirType === 3 && +currentData.scene === -1" />

            <img v-if="currentData.dirPage" class="deploy-img" :src="currentData.picUrl || ''" alt="" />

            <bottom-menu v-show="[2, 4].includes(+currentData.dirType) && !currentData.dirPage" :specifyScene="isInside ? 'inside' : 'outside'" />

            <center-map ref="centerMap" />
          </div>

          <save-button v-if="!currentData.dirPage && !control.showIfame" domId="rightCenterMap" @save="saveClick" :currentData="currentData" :isInside="isInside" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { editPptPic } from '@/api/checkPlan'
import inSide from './inSide'
import outSide from './outSide'
import { disaster, deploy } from '@/api/sandTable'

import CenterMap from './components/CenterMap'
import NavBar from './components/NavBar'
import LeftBar from './components/LeftBar'
import EditTitle from './components/PopUp/EditTitle'
import TopHint from './components/TopHint'
import SaveButton from './components/SaveButton'
import BottomMenu from '@/components/BottomMenu'
import Dispatch from './components/Dispatch'
import DeduceData from './components/DeduceData'

import PowerTransfer from './components/PowerTransfer/index.vue'

export default {
  mixins: [inSide, outSide],

  components: {
    CenterMap,
    NavBar,
    LeftBar,
    EditTitle,
    TopHint,
    SaveButton,
    BottomMenu,
    Dispatch,
    DeduceData,
    PowerTransfer
  },

  data() {
    return {
      isInside: false,
      listCurrent: 0,
      currentData: {},
      control: {
        showIfame: false
      },

      fireData: []
    }
  },

  provide() {
    return {
      cesiumBox: this.cesiumBox
    }
  },

  methods: {
    cesiumBox() {
      return this.$refs.centerMap?.cesiumBox()
    },

    // 获取灾情模拟的数据
    getDisasterData() {
      return disaster
        .get({
          sandTableId: this.$route.query.id,
          status: 1
        })
        .then((data) => {
          this.fireData = Array.isArray(data) ? data : []

          if (data.length) {
            data.forEach((item) => {
              if (item.buildId) {
                this.inSideData.push(item)
              } else if (item.position) {
                // 外景的数据不是标准化的，需要另外处理一遍
                this.outSideData.push(this.formatOutsideDrawData(item, 'fire'))
              }
            })
          }
        })
    },

    //获取部署阶段数据
    getDeploysByTaskData(info) {
      return deploy
        .get({
          sandTableId: this.$route.query.id,
          grade: 1,
          status: 1,
          ...info
        })
        .then((data) => {
          if (info.scene) {
            this.inSideData.push(...data)
            return
          }

          data.forEach((item) => {
            const { position, config, operationJson, wd } = item
            // 必须有坐标点以及配置项
            if ((position || wd) && (config || operationJson)) {
              this.outSideData.push(this[item.carId ? 'formatOutsideDrawDataByCar' : 'formatOutsideDrawData'](item))
            }
          })
        })
    },

    //切换ppt
    checkItemDeploy(index, item) {
      if (this.listCurrent === index && index !== 0) return

      this.listCurrent = index
      this.currentData = item

      this.$nextTick(() => {
        this.$refs.topHint?.setValue(item.content)
        // this.$refs.leftDeploy?.close()
        // this.$EventBus.$emit('clear.tab.bar')
      })

      //处理判断渲染
      this.decisionPage(item)
    },

    //处理判断渲染
    async decisionPage(item) {
      //目录页不执行
      if (item.dirPage) return

      this.isInside = Boolean(item.scene === -1 ? 0 : item.scene)
      // this.$EventBus.$emit('menu.bar.show.outside', !this.isInside)

      // 清空外景数据
      this.clearOutSideData()
      // //清除内景数据
      this.clearInsideOtherData()

      //火灾设定阶段
      if (+item.dirType >= 2) {
        await this.getDisasterData()
      }

      // 作战部署阶段
      if (+item.dirType >= 4) {
        await this.getDeploysByTaskData({
          scene: +this.isInside,
          type: +this.isInside
        })
      }

      // //进入内景
      if (this.isInside) {
        return this.getInside(this.currentData)
      }

      this.drawHandlerData(+this.currentData.dirType)
    },

    //保存按钮
    saveClick(picUrl) {
      if (!this.isInside && +this.currentData.dirType !== 3) {
        this.saveAddress()
      }
      this.saveDataFn('picUrl', picUrl).then(() => {
        this.$message.success('成功保存')
      })
    },

    //保存接口
    saveDataFn(key, value) {
      const { title, content, picUrl, id, dirType, buildId, priority, scene, templateId, position } = this.currentData
      return editPptPic({
        title,
        content,
        picUrl,
        id: id || undefined,
        sandTableId: +this.$route.query.id,
        dirType,
        buildId: buildId || undefined, //为0则不传值
        templateId: templateId || undefined,
        priority,
        scene,
        position,
        [key]: value
      }).then((data) => {
        //因为不重新进行请求,因此不允许脱离引用
        if (key === 'picUrl') {
          // 提升视觉体验,清除图片
          this.currentData[key] = ''
        }

        this.$nextTick(() => {
          Object.assign(this.currentData, {
            id: data.id,
            [key]: data[key]
          })
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import './common.scss';
.check-plan {
  background-color: #020f21;
  width: 100%;
  height: 100vh !important;
  position: relative;
  color: #fff;
  .content-body {
    display: flex;
    @include position(absolute, 48px, 0, 0);
    .bottom-style {
      position: relative;
      flex: 1;
      display: flex;
      flex-direction: column;
      height: calc(100vh - 48px);
      .right-center-map {
        flex: 1;
        position: relative;
        overflow: hidden;
        #rightCenterMap {
          height: 100%;
          .deploy-img {
            @include position(absolute, 0, 0);
            height: calc(100vh - 48px);
            width: calc(100vw - 326px);
            z-index: 4;
          }
        }
      }
    }
  }
}
</style>
