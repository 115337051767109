<template>
  <div>
    <right-menu ref="rightMenu" />
  </div>
</template>

<script>
import AMap from 'AMap'
import ZK from '@gis/zk-base'

import api from '@/api/sandTable'
import RightMenu from './RightMenu'
import { coordinates2Cartesian3 } from '@/utils/cesiumUtil'
import coordinateTransform from '@/utils/coordinateTransform'

const defaultIcon = require('@/assets/images/model/location-1.png')
const activeIcon = require('@/assets/images/model/location-2.png')
const defaultColor = '#0C8EFF'
const highlightColor = '#FE3232'

export default {
  components: {
    RightMenu
  },

  inject: ['cesiumBox'],

  created() {
    // 相互独立的异步处理，用来判断是否需要处理旧的icon数据
    this.alreadyHandlerOldPoint = false
    this.selectedTeamStand = []
  },

  mounted() {
    this.$nextTick(() => {
      this.fetchTeamStandList()
    })
  },

  beforeDestroy() {
    this.resetPowerTransfer()
  },

  methods: {
    // 获取调派队站列表，根据队站列表经纬度，在地图渲染
    fetchTeamStandList() {
      api.powerTransfer.getNextOrgan().then((data) => {
        this.drawUnitPoints()
        this.drawPoints(data)
        this.pathPlann(data)
      })
    },

    resetPowerTransfer() {
      if (this.unitPoint) {
        this.unitPoint.remove()
      }

      if (this.teamStandEntitys) {
        this.teamStandEntitys.forEach(item => item.primitive.remove())
      }

      this.removeRouterLayer && this.removeRouterLayer()
    },

    // 绘制单位图标
    drawUnitPoints() {
      const entityGenerate = this.cesiumBox().getEntity()
      const companyInfo = this.$store.state.user.currentCompanyInfo

      const p = coordinateTransform.gcj02Wg84(companyInfo.longitude, companyInfo.latitude)

      const position = coordinates2Cartesian3(p.lng, p.lat, 0)
      console.log(p, position)

      this.unitPoint = entityGenerate.textLabel('unit', position, {
        text: companyInfo.name,
        color: '#fff',
        backgroundColor: '#d81e06',
        pointIcon: require('@/assets/images/model/unit-icon.png'),
        pointIconWidth: 32,
        pointIconHeight: 32,
        distanceDisplayCondition: [0, Number.POSITIVE_INFINITY]
      })
    },

    // 绘制队站图标
    drawPoints(data) {
      const entityGenerate = this.cesiumBox().getEntity()

      this.teamStandEntitys = []

      const cartesian3s = []

      data.forEach((item) => {
        if (!item.longitude || !item.latitude) return

        const id = `teamStand-${item.id}`
        const position = coordinates2Cartesian3(item.longitude, item.latitude, 20)

        cartesian3s.push(position)

        const primitive = entityGenerate.textLabel(id, position, {
          text: item.name,
          color: '#fff',
          backgroundColor: defaultColor,
          pointIcon: defaultIcon,
          pointIconWidth: 24,
          pointIconHeight: 30,
          distanceDisplayCondition: [0, Number.POSITIVE_INFINITY]
        })

        this.teamStandEntitys.push({
          primitive,
          id: item.id
        })
      })

      // 飞过去
      const boundingSphere = Cesium.BoundingSphere.fromPoints(cartesian3s)
      window.viewerData.camera.flyToBoundingSphere(boundingSphere)

      // 初始化之前的数据
      this.accordingSelectedIcon()
    },

    // 获取离单位最近的3个队站，并进行路线规划
    pathPlann(data) {
      const recently = this.getRecently(data)

      const companyInfo = this.$store.state.user.currentCompanyInfo
      const unitPoint = [companyInfo.longitude, companyInfo.latitude]

      const layer = new ZK.VectorLayer('teamStationRoadNetwork')
      window.viewerData.addLayer(layer)

      this.teamStationRoadNetworkLayer = layer
      this.removeRouterLayer = () => {
        window.viewerData.removeLayer(this.teamStationRoadNetworkLayer)
      }

      recently.forEach((item) => {
        this.getDirectionByAmap(unitPoint, [item.longitude, item.latitude])
      })
    },

    // 利用amap提供的接口做路径规划，求最短路径
    getDirectionByAmap(origin, destination) {
      AMap.plugin('AMap.Driving', () => {
        let driving = new AMap.Driving({
          policy: AMap.DrivingPolicy.LEAST_DISTANCE
        })

        driving.search(new AMap.LngLat(...origin), new AMap.LngLat(...destination), (status, result) => {
          if (status !== 'complete') return

          this.drawRouteLine(result.routes[0].steps)
        })
      })
    },

    // 渲染最短路径
    drawRouteLine(steps) {
      let arr = []
      steps.forEach((item) => {
        item.path.forEach((p) => {
          p = coordinateTransform.gcj02Wg84(p.lng, p.lat)
          arr.push(`${p.lng}, ${p.lat}`)
        })
      })

      let polyline = new ZK.Polyline(arr.join(';'))
      polyline.setStyle({
        width: 3,
        material: ZK.Color.RED,
        clampToGround: true
      })

      this.teamStationRoadNetworkLayer.addOverlay(polyline)
    },

    // 计算最近的三个队站
    getRecently(data) {
      const companyInfo = this.$store.state.user.currentCompanyInfo
      const unitPoint = turf.point([companyInfo.longitude, companyInfo.latitude])

      data.forEach((item) => {
        if (!item.longitude || !item.latitude) return

        const to = turf.point([item.longitude, item.latitude])

        item.distance = turf.rhumbDistance(unitPoint, to)
      })

      data.sort((pre, next) => {
        return pre.distance - next.distance
      })

      return data.slice(0, 3)
    },

    // 针对第一次加载，根据后端数据高亮所选的icon
    accordingSelectedIcon() {
      const data = this.selectedTeamStand

      if (!data.length) return

      data.forEach((id) => {
        const teamStandData = this.teamStandEntitys.find(item => +item.id === +id)

        const image = activeIcon
        const color = Cesium.Color.fromCssColorString(highlightColor)

        teamStandData.primitive.entity.label.backgroundColor = color
        teamStandData.primitive.entity.billboard.image = image
      })
    }
  }
}
</script>
