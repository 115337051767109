import modelApi from '@/api/model'
import HtmlHostMsg from 'HtmlHostMsg'
export default {
  data() {
    return {
      floorLists: []
    }
  },

  created() {
    this.inSideData = []
    this.$$events = [
      [
        'iframe.load.complete',
        () => {
          this.isReady = true
        }
      ]
    ]
    this.$$events.forEach(item => this.$EventBus.$on(...item))
  },

  beforeDestroy() {
    this.$$events.forEach(item => this.$EventBus.$off(...item))
  },

  methods: {
    //进入内景
    getInside(currentData) {
      return modelApi
        .getbuildTreeData({
          id: this.$route.query.unitId,
          buildId: currentData.rootBuildId
        })
        .then((data) => {
          this.floorLists = data[0].children

          this.$nextTick(() => {
            this.$refs.centerMap.onEnterInternal({
              id: currentData.rootBuildId,
              modelUrl: data[0].modelUrl
            })

            this.modelUrl = data[0].modelUrl

            //隐藏外景/显示内景
            this.$EventBus.$emit('change.ceter.map.status', true)

            const interval = setInterval(() => {
              if (this.inSideData && this.isReady) {
                setTimeout(() => {
                  this.filterInsideData()

                  if (!currentData.modelKey) return

                  HtmlHostMsg.event('floor', {
                    view: true,
                    modelKey: currentData.modelKey,
                    otherFloors: true,
                    html: true
                  })
                }, 2000)

                clearInterval(interval)
              }
            }, 200)
          })
        })
    },

    //渲染内景数据
    filterInsideData() {
      this.inSideData = this.inSideData.filter((item) => {
        if (item.draw) delete item.draw

        if (typeof item.config === 'string') {
          item.config = JSON.parse(item.config)
        }

        item.key = item.deployType.deployKey

        if (this.modelUrl === item.buildModelUrl) {
          return true
        }
      })
      HtmlHostMsg.event('tool', this.inSideData)
    },

    //清除数据
    clearInsideOtherData() {
      if (!this.inSideData?.length) return

      let isHave = []
      this.inSideData.forEach((item) => {
        if (isHave.includes(item.key)) return
        isHave.push(item.key)

        HtmlHostMsg.event('tool', { clear: true, draw: true, key: item.key })
      })
      this.inSideData = []
    }
  }
}
