<template>
  <div class="containew-box">
    <cesium-box v-if="showCesiumBox" v-show="!isInside" ref="cesiumBox" @reset="handlerDrawCancel" @success="handlerDrawSuccess" @del-entity="handlerDeleteEntity" @enter-internal="onEnterInternal" />
    <!-- 内景优诺 -->
    <t-d-contain v-show="isInside" />
  </div>
</template>

<script>
import ZK from '@gis/zk-base'
import { PLANDRAWTYPE, SCEN_ARIO, TOOL_COLOR_LIST } from '@/utils/conf'
import modelApi from '@/api/model'

import CesiumBox from '@/components/ModelComponents/CesiumBox/index'
import TDContain from '@/components/TDContain'
export default {
  components: {
    CesiumBox,
    TDContain
  },

  data() {
    return {
      showCesiumBox: true,
      isInside: false
    }
  },

  computed: {
    unitId() {
      return this.$route.query.unitId
    }
  },

  created() {
    this.cacheEntity = {}
    this.$event = [
      ['change.ceter.map.status', status => this.isInside = status],
      [
        // 控制模型开始绘制数据
        'control.cesium.model.paint',
        (data) => {
          this.renderDrawData(data)
        }
      ]
    ]

    this.$event.forEach(item => this.$EventBus.$on(...item))
  },

  mounted() {
    setTimeout(() => {
      this.fetchCompanyInfoByUnitId()
    }, 1000)
  },

  beforeDestroy() {
    this.$event.forEach(item => this.$EventBus.$off(...item))
    this.resetOperate()

    this.changeSceneStatus(false)
  },

  methods: {
    cesiumBox() {
      return this.$refs.cesiumBox
    },

    handlerDrawCancel() {
      this.$EventBus.$emit('draw.cancel.callback.outside')
    },

    handlerDrawSuccess(data) {
      this.$EventBus.$emit('draw.success.callback.outside', data)
    },

    handlerDeleteEntity(data) {
      this.$EventBus.$emit('draw.delete.callback.outside', data)
    },

    onEnterInternal(data) {
      this.$store.dispatch('planDrill/setThingModel', data)
      // @TODO: 这里进去的是默认第一个建筑体, 没有考虑多个建筑体的情况
      this.changeSceneStatus(true)
    },

    changeSceneStatus(isInside = false) {
      this.$store.dispatch('planDrill/changeSceneStatus', isInside)
    },

    resetOperate() {
      // 移除监听事件
      if (this.offListeners) {
        this.offListeners()
      }

      // 移除3dtile
      this.cesiumBox().removeTilesetLayer()
      // 移除建筑体信息
      this.removeBuilderDrawData()
      // 移除建筑体框
      this.handlerHideLastOverlay()
      // 移除建筑体测量数据
      this.resetMeasureEntity()
      // 清除内景数据
      this.$store.dispatch('planDrill/resetThingModel')
    },
    /**
     * 根据unitId获取单位信息
     * 请求建筑体信息进行加载并渲染在视图上(以供进入内景的入口)
     */
    fetchCompanyInfoByUnitId() {
      modelApi
        .getCompanyInfo({
          unitId: this.unitId
        })
        .then((data) => {
          this.$store.commit('user/SET_CURRENT_COMPANY_INFO', data)
          this.initCesiumByCompanyInfo(data)
        })
    },

    // 根据单位信息初始化cesium
    initCesiumByCompanyInfo(data) {
      // 初始化cesium 3dtile
      this.cesiumBox()
        .init3dTilesetLayer([data.modelUrl])
        .then(() => this.cesiumBox().flyToDefaultPerspective(data.id))

      // 加载建筑体
      this.fetchBildTreeByBuildId()

      // 订阅事件
      this.listenMouseEvent()
    },

    fetchBildTreeByBuildId() {
      return modelApi
        .getUnitDrawList({
          drawType: PLANDRAWTYPE.BUILD,
          scene: SCEN_ARIO.EXTERNAL,
          unitId: this.unitId
        })
        .then((data) => {
          this.handlerBuilderDrawData(data)
          this.handlerGetBuildDrawDataByInside(data)
        })
    },

    // 处理内景
    handlerGetBuildDrawDataByInside(data) {
      // 获取模型地址
      const lists = data.filter(item => item.buildId && item.modelUrl)

      if (!lists.length) return

      // 加载内景并做隐藏处理
      // 默认加载第一个
      const { modelUrl, buildId } = lists[0]
      this.$store.commit('planDrill/SET_PLANDRILL_BUILDID', buildId)
      this.$store.commit('planDrill/SET_PLANDRILL_LOADURL', modelUrl)
    },

    // 监听触控事件
    listenMouseEvent() {
      const viewer = this.cesiumBox().getViewer()
      const listeners = [
        [ZK.MouseEventType.CLICK, this.handlerLeftClick.bind(this)],
        [ZK.MouseEventType.WHEEL, this.handlerHideLastOverlay.bind(this)],
        [ZK.MouseEventType.PINCH_MOVE, this.handlerHideLastOverlay.bind(this)]
      ]

      listeners.forEach(item => viewer.on(...item))

      this.offListeners = () => {
        console.log('销毁订阅事件')
        listeners.forEach(item => viewer.off(...item))
      }
    },

    handlerBuilderDrawData(data) {
      const cacheBuildDrawDataLists = this.cacheBuildDrawDataLists
      if (cacheBuildDrawDataLists && cacheBuildDrawDataLists.length > 0) return

      this.cacheBuildDrawData = {}

      data = data.filter(item => !!item.drawTool && !!item.config)

      for (const item of data) {
        //禁止绘制罐体
        if (item.drawToolId === 56) {
          continue
        }
        // 获得配置表
        const config = JSON.parse(item.config)
        const drawConfig = JSON.parse(item.drawTool.config)
        const key = item.id

        // 绘画工具id
        const drawToolId = item.drawTool.id
        // 生成信息弹窗
        const overlay = this.cesiumBox().generateOverlay({ ...item, drawConfig, isViews: true, hideBtn: true })
        // 获取绘制类
        const entityGenerate = this.cesiumBox().getEntity()

        try {
          const primitive = entityGenerate[drawConfig.child || drawConfig.drawType](`${drawToolId}-${key}`, config.positions, {
            icon: drawConfig.icon,
            label: {
              text: item.drawName
            },
            ...drawConfig.options,
            isBuild: true
          })

          if (!this.cacheBuildDrawData[drawToolId]) {
            this.cacheBuildDrawData[drawToolId] = {}
          }

          // 添加实体
          this.cacheBuildDrawData[drawToolId][key] = {
            primitive,
            overlay,
            data: item,
            measureData: config.measureData
          }

          if (!this.cacheBuildDrawDataLists) this.cacheBuildDrawDataLists = []
          // 用来删除的
          this.cacheBuildDrawDataLists.push({
            primitive,
            overlay
          })
        } catch (error) {
          console.log(error)
        }
      }
    },

    handlerLeftClick({ target }) {
      this.handlerHideLastOverlay()

      const lastMeasureEntity = this.currentMeasureEntity
      if (lastMeasureEntity) {
        this.resetMeasureEntity(lastMeasureEntity)
        this.currentMeasureEntity = null
      }

      // 绘画时禁止
      if (this.cesiumBox()?.isDrawing) return

      if (!Cesium.defined(target) || !Cesium.defined(target.id)) return

      const objId = target.id._objId
      this.changeOverlay(objId, true)
      this.changeShownMeasureData(objId)
    },

    handlerHideLastOverlay() {
      // 建筑体弹窗
      if (this.lastOverlayId) {
        this.changeOverlay(this.lastOverlayId)
        this.lastOverlayId = null
      }
    },

    changeOverlay(objId, isShown = false) {
      if (!objId) return

      const [root, id] = objId.split('-')

      let vessel = {}

      if (this.cacheBuildDrawData[root] && this.cacheBuildDrawData[root][id]) {
        vessel = this.cacheBuildDrawData[root][id]
      }

      const overlay = vessel.overlay

      if (!overlay) return

      overlay[isShown ? 'show' : 'hide']()

      if (isShown) {
        this.lastOverlayId = objId
      }
    },

    changeShownMeasureData(objId) {
      if (!objId) return

      const [root, id] = objId.split('-')

      if (!this.cacheBuildDrawData[root]) return
      let vessel = this.cacheBuildDrawData[root][id]

      const measureData = vessel.measureData

      if (!measureData) return
      // 数据格式
      this.currentMeasureEntity = this.cesiumBox().getDrawMeasureData(measureData, vessel.data, TOOL_COLOR_LIST[vessel.data.drawType])
    },

    resetMeasureEntity(data = this.currentMeasureEntity) {
      data = data || {}
      if (Object.keys(data).length > 0) {
        Object.keys(data).map((key) => {
          data[key].remove()
        })
      }
    },

    removeBuilderDrawData() {
      const lists = this.cacheBuildDrawDataLists || []
      lists.forEach((item) => {
        item.primitive.remove()
        item.overlay.destroy()
      })
      this.cacheBuildDrawDataLists = []
    },

    handlerFlyToDefault(data) {
      const viewer = this.cesiumBox().getViewer()
      viewer.flyToPosition(ZK.Position.fromObject(data))
    },

    renderDrawData({ data, callback }) {
      // item = this.formatOutsideDrawData(item)
      for (const item of data) {
        try {
          // 获得配置表
          const config = item.config ? JSON.parse(item.config) : {}
          const drawConfig = JSON.parse(item.drawTool.config)
          drawConfig.options = drawConfig.options || {}

          const key = item.id

          // 绘画工具id
          const drawToolId = item.drawTool.id
          // 生成信息弹窗
          const overlay = this.cesiumBox().generateOverlay({ ...item, drawConfig, isViews: item.isView })
          // 获取绘制类
          const entityGenerate = this.cesiumBox().getEntity()

          let positions = config.positions
          if (positions instanceof Array) {
            if (positions[0] instanceof Object) {
              positions = positions.map(p => new Cesium.Cartesian3(p.x, p.y, p.z))
            }
          } else {
            positions = new Cesium.Cartesian3(positions.x, positions.y, positions.z)
          }

          const icon = drawConfig?.marker?.url || item?.drawTool?.icon
          let type = drawConfig.child || drawConfig.drawType

          // @TODO: 后续要将消防设施等点类型改成textLabel
          if (type === 'point') {
            type = 'textLabel'

            drawConfig.options = {
              ...drawConfig.options,
              color: '#fff',
              backgroundColor: '#f39c12'
            }
          }

          const entityId = `${drawToolId}-${key}`

          if (!config.label) {
            config.label = {
              text: item.drawName
            }
          }

          const primitive = entityGenerate[type](entityId, positions, {
            iconWidth: 30,
            iconHeight: 40,
            text: item.drawName,
            modelUrl: item.modelUrl,
            label: config.label || '',
            minimumHeights: config.minimumHeights || [],
            waterPositions: drawConfig.waterPositions || [],
            waterPipePositions: drawConfig.waterPipePositions || [],
            centerPosition: { lon: item.longitude, lat: item.latitude },
            icon,
            itemInfo: item,
            pointIcon: icon,
            pointPixelOffset: [0, -20],
            ...drawConfig.options,
            backgroundColor: item.color || drawConfig.options.backgroundColor,
            distanceDisplayCondition: [0, Number.POSITIVE_INFINITY]
          })

          if (!this.cacheEntity[drawToolId]) {
            this.cacheEntity[drawToolId] = {}
          }

          // 添加实体
          // 这个对象用来查询
          this.cacheEntity[drawToolId][key] = {
            primitive,
            overlay,
            data: item
          }
          // 这个用来方便做清除操作或循环查询
          if (!this.cacheLists) this.cacheLists = []

          this.cacheLists.push({
            id: key,
            overlay,
            primitive,
            data: item,
            drawToolId
          })

          if (callback && typeof callback === 'function') {
            // eslint-disable-next-line callback-return
            callback({
              id: key,
              entityId,
              overlay,
              primitive
            })
          }
        } catch (error) {
          console.log(error)
        }
      }
    },

    //获取当前相机位置/偏移角度
    getCameraPosition() {
      const viewer = this.cesiumBox()?.getViewer()
      const { position, heading, pitch, roll } = viewer.camera
      const { lng, lat, alt } = ZK.Transform.transformCartesianToWGS84(position)
      return {
        lng,
        lat,
        alt,
        heading: ZK.Math.toDegrees(heading),
        pitch: ZK.Math.toDegrees(pitch),
        roll: ZK.Math.toDegrees(roll)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.container-box {
  overflow: hidden;
  flex: 1;
  height: 100%;
  // > div {
  //   height: 100%;
  // /deep/ #cesiumContainerBox {
  //   position: relative;
  //   .zk-compass {
  // top: 30px;
  // }
  // }
  // }
}
</style>
