<template>
  <div class="right-menu menu-box">
    <p class="title">力量调派</p>
    <div class="right-menu_content">
      <ul v-for="item in hasSecondTeamStand" :key="item.id">
        <p>{{ item.organName }}</p>

        <template v-if="item.cars.length > 0">
          <li v-for="car in item.cars" :key="car.id">
            <el-image fit="cover" :src="car.carImageUrl"></el-image>
            <p>{{ car.carName }}</p>
          </li>
        </template>

        <el-empty v-else :image="require('@/assets/images/empty.png')" :image-size="60" description="暂无车辆信息"></el-empty>
      </ul>
    </div>
  </div>
</template>

<script>
import api from '@/api/sandTable'

export default {
  data() {
    return {
      hasSecondTeamStand: []
    }
  },

  created() {
    this.initFetch()
  },

  computed: {
    sandTableId() {
      return this.$route.query.id
    }
  },

  methods: {
    initFetch() {
      this.fetchSandTableTeamStand().then((data) => {
        this.$emit(
          'update',
          data.map(item => item.organId)
        )
      })
    },

    // 获取右侧队站列表
    fetchSandTableTeamStand() {
      return api.powerTransfer
        .getList({
          sandTableId: this.sandTableId,
          status: 0
        })
        .then((data) => {
          this.hasSecondTeamStand = data || []

          return data
        })
    },

    // 根据organId查找对应的id
    findIdByOrganId(id) {
      const result = this.hasSecondTeamStand.find(item => +item.organId === +id) || {}

      return result.id
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.menu-box {
  @include size(48px, calc(100vh - 48px));
  background: linear-gradient(180deg, rgba(6, 19, 38, 0.53), rgba(17, 49, 94, 0.53));
  border-left: 1px solid #143e6e;
  @include flex-center();
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;

  &::before {
    content: '';
    @include size(100%);
    background-color: #061121;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
}

.right-menu {
  width: 350px;
  padding: 16px;
  box-sizing: border-box;
  font-size: var(--font-size);
  align-items: flex-start;
  * {
    box-sizing: border-box;
  }

  .title {
    color: #fff;
  }

  &_content {
    height: calc(100% - 30px - 50px - 10px);
    overflow-y: auto;
  }

  ul {
    min-width: 282px;
    margin-top: 16px;
    padding: 12px 16px 16px;
    background-color: #0a2543;
    border-radius: 2px;
    @include flex-center();
    flex-wrap: wrap;
    position: relative;

    & > p {
      color: #fff;
      flex: 1 0 100%;
      margin-bottom: 11px;
    }

    & > .el-button {
      position: absolute;
      top: 5px;
      right: 16px;
    }

    &::v-deep .el-empty {
      width: 100%;
      padding-top: 20px;
      padding-bottom: 0;
      text-align: center;
      .el-empty__description p {
        color: #fff;
      }
    }

    li {
      @include size(130px, 110px);
      box-sizing: border-box;
      padding: 8px;
      border-radius: 2px;
      background-color: #122e4d;
      @include flex-center(center, column);

      color: #fff;
      font-size: var(--zk-min-size);
      margin-bottom: 8px;

      cursor: pointer;
      user-select: none;
      transition: all 0.2s;

      &:not(:nth-of-type(2n)) {
        margin-right: 8px;
      }

      .el-image {
        @include size(105px, 60px);
        margin-bottom: 5px;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &_footer {
    @include flex-center(center);
    @include size(100%, 51px);
    background-color: #051d3f;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

.choose-car-box {
  max-height: 500px;
  overflow-y: auto;
}

.choose-car-lists {
  @include flex-center();
  flex-wrap: wrap;

  .checkbox-item {
    @include size(130px, 110px);
    box-sizing: border-box;
    flex-direction: column;
    @include flex-center(center);
    position: relative;
    margin-bottom: 8px;
    background-color: #122e4d;
    cursor: pointer;
    user-select: none;
    &:not(:nth-of-type(3n)) {
      margin-right: 8px;
    }

    &_active {
      border: 1px solid var(--zk-color);
    }

    .el-image {
      @include size(105px, 61px);
    }

    p {
      color: #fff;
      font-size: var(--zk-min-size);
      @include flex-center(center);
      margin-top: 8px;
      img {
        @include size(13px);
        margin-right: 5px;
      }
    }

    .el-checkbox {
      opacity: 0;
      @include size(100%);
      position: absolute;
    }
  }
}
</style>
