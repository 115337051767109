<template>
  <button class="right-btn" @click.stop="saveButton" :style="{ top: +currentData.dirType === 1 ? '48px' : '12px' }">
    <img src="@/assets/images/checkPlan/cunCaoGaoIcon.png" alt="" />
    <span>保存</span>
  </button>
</template>

<script>
import { htmlToImg } from '@/plugins/vuetopdf'
import { dataURLtoFile, uploadPreprocess } from '@/utils/util'
import { uploadFile } from '@/api/upload'

import HtmlHostMsg from 'HtmlHostMsg'
export default {
  props: {
    isInside: Boolean,
    currentData: Object,
    domId: String
  },

  methods: {
    async saveButton() {
      const loading = this.$loading({
        lock: true,
        text: '正在保存中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      try {
        let file
        if (this.isInside) {
          file = await this.inSideScreenshot()
        } else {
          const dom = document.getElementById(this.domId)
          file = await htmlToImg(dom)
        }

        const md5File = await uploadPreprocess(file)
        const url = await uploadFile(md5File)

        this.$emit('save', window.decodeURIComponent(url))
      } catch (err) {
        this.$message.error('系统错误,保存失败')
        console.log(err)
      }
      loading.close()
    },

    //内景截图
    inSideScreenshot() {
      HtmlHostMsg.event('screen')

      return new Promise((resolve) => {
        HtmlHostMsg.handler('screen', ({ data }) => {
          const file = dataURLtoFile(data.data.dataUrl)
          resolve(file)
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.right-btn {
  position: absolute;
  right: 0;
  z-index: 4;
  width: 88px;
  height: 40px;
  line-height: 40px;
  background-color: rgba(26, 49, 81, 0.8);
  color: #18cffd;
  border: none;
  border-radius: 20px 0px 0px 20px;
  font-size: 16px;
  cursor: pointer;
  > img {
    margin-right: 8px;
    width: 14px;
    height: 14px;
    transform: translateY(-2px);
  }
}
</style>
