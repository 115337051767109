<template>
  <div class="check-left-bar">
    <nav class="content-body">
      <li v-for="(item, index) in leftBarList" :key="index">
        <span class="serial">{{ index + 1 }}、</span>
        <div
          class="ppt-img"
          @click.stop="checkItemDeploy(index, item)"
          :class="{
            'is-current': listCurrent === index,
            'is-more': leftBarList.length > 9
          }"
        >
          <div class="is-text" v-if="!item.picUrl">
            <h4>
              {{ filterTitle(item, $route.query, leftBarList) }}
            </h4>
            <span>请点击保存按钮生成缩略图</span>
          </div>
          <img v-else class="is-deploy" :src="item.picUrl" alt="" />
        </div>
      </li>
    </nav>
  </div>
</template>

<script>
import { getPptList } from '@/api/checkPlan'

export default {
  props: {
    listCurrent: Number
  },

  data() {
    return {
      leftBarList: []
    }
  },

  computed: {
    filterTitle() {
      return (item, routeData, data) => {
        const { dirType, modelKey, scene, rootBuildName } = item
        let title
        const buildName = rootBuildName ? rootBuildName + '-' : ''
        switch (+dirType) {
          case 1:
            title = data[0].title || routeData.name
            break
          case 2:
            title = `灾情设定-${buildName}${modelKey}着火`
            break
          case 3:
            title = '力量调派'
            break
          case 4:
            title = `作战部署-${buildName}${modelKey}${scene ? '内' : '外'}攻`
            break
          case 5:
            title = '推演标记资料 '
            break
        }

        return title
      }
    }
  },

  created() {
    getPptList({ sandTableId: +this.$route.query.id }).then((data) => {
      this.leftBarList = data
      this.fly()
      // this.$emit('close-default-fly', !this.leftBarList[0].position)
    })
  },

  methods: {
    checkItemDeploy(index, item) {
      this.$emit('check-item-deploy', index, item)
    },

    fly() {
      this.checkItemDeploy(0, this.leftBarList[0])
    }
  }
}
</script>

<style scoped lang="scss">
@import '../common.scss';
.check-left-bar {
  width: 326px;
  z-index: 4;
  background-color: #101d2f;
  color: #fff;
  .content-body {
    box-sizing: border-box;
    height: calc(100vh - 48px);
    padding: 3px 15px 13px 10px;
    margin-right: 5px;
    @include scrollY();
    li {
      display: flex;
      margin-top: 13px;
      justify-content: space-between;
      .serial {
        margin-right: -7px;
        font-size: 16px;
      }
      .ppt-img {
        width: 277px;
        height: 159px;
        box-sizing: border-box;
        background: url('~@/assets/images/checkPlan/bg.png') no-repeat;
        background-size: 100% 100%;
        border-radius: 2px;
        cursor: pointer;
        @include flex(center);
        flex-direction: column;
        outline: 1px solid #1a3151;
        &.is-current {
          outline: 1px solid #1077ba;
          h4 {
            color: #18cffd;
          }
        }
        &:hover {
          outline: 1px solid #1077ba;
          h4 {
            color: #18cffd;
          }
        }
        &.is-more {
          width: 265px;
        }
        .is-text {
          text-align: center;

          > h4 {
            font-size: 16px;
          }
          > span {
            font-size: 12px;
            color: #c1cee1;
            margin-top: 7px;
          }
        }
        .is-deploy {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
