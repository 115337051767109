<template>
  <div class="edit-title">
    <div class="is-look" v-if="!isEdit">
      <span>{{ title }}</span>
      <img src="@/assets/images/checkPlan/bianJiIcon.png" alt="" @click.stop="isEdit = true" />
    </div>
    <div class="is-edit" v-else>
      <el-input v-model="title"></el-input>
      <div class="btn-list">
        <img src="@/assets/images/checkPlan/wanChengIcon.png" alt="" @click.stop="saveTitle" />
        <img src="@/assets/images/checkPlan/guanBiIcon.png" alt="" @click.stop="clearInput" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentData: Object
  },

  data() {
    return {
      title: this.currentData.title || this.$route.query.name,
      isEdit: false
    }
  },

  methods: {
    clearInput() {
      this.isEdit = false
      this.title = this.currentData.title || this.$route.query.name
    },

    setValue() {
      this.title = this.currentData.title
    },

    saveTitle() {
      this.$parent.saveDataFn('title', this.title).then(() => {
        this.isEdit = false
      })
    }
  }
}
</script>

<style scoped lang="scss">
.edit-title {
  width: 820px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  box-sizing: border-box;
  background-color: rgba(2, 15, 33, 0.8);
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  .is-look {
    font-size: 36px;
    color: #18cffd;
    > img {
      width: 20px;
      height: 20px;
      transform: translateY(3px);
      margin-left: 8px;
      cursor: pointer;
    }
  }
  .is-edit {
    display: flex;
    align-items: flex-end;
    /deep/.el-input {
      width: 680px;
      border-bottom: 1px solid #1077ba;
      padding-bottom: 16px;
      margin-right: 14px;
      > .el-input__inner {
        font-size: 36px;
        color: #18cffd !important;
        border: none !important; //这里为什么important你心里应该有点数
        background-color: unset !important;
        box-shadow: unset !important;
      }
    }
    .btn-list > img {
      cursor: pointer;
      width: 18px;
      height: 18px;
      &:first-child {
        margin-right: 20px;
      }
    }
  }
}
</style>
