<template>
  <div class="power-transfer">
    <div class="body-re">
      <table border="1px" v-if="header.length">
        <tr class="title">
          <th>车辆调度表</th>
          <th v-for="(item, index) in header" :key="index">{{ item.label }}</th>
        </tr>
        <tr class="content" v-for="(item, index) in tableData" :key="index">
          <td>{{ item.name }}</td>
          <td v-for="(group, key) in item.list" :key="key">{{ group }}</td>
        </tr>
      </table>
      <div v-else class="no-thing">暂无数据</div>
    </div>
    <!-- <div class="table" v-if="header.length">
      <ul class="title">
        <li>车辆调度表</li>
        <li v-for="(item,index) in header" :key="index">{{item.label}}</li>
      </ul>
      <div class="content">
        <ul>
          <li v-for="(item,index) in tableData" :key="index">
            <div>{{item.name}}</div>
            <div v-for="(group,key) in item.list" :key="key">{{group}}</div>
          </li>
        </ul>
      </div>
    </div>
    <div v-else class="no-thing">暂无数据</div> -->
  </div>
</template>

<script>
import { getOverviewList } from '@/api/checkPlan'
export default {
  data() {
    return {
      header: [],
      tableData: []
    }
  },

  created() {
    getOverviewList({
      sandTableId: this.$route.query.id,
      status: 1
    }).then((data) => {
      this.header = data.heads || []
      this.tableData = data.table || []
    })
  },

  methods: {
    tabMenu(index) {
      this.curMenuIndex = index
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../common.scss';
.power-transfer {
  width: 100%;
  color: #fff;
  background-color: #020f21;
  @include position(absolute, 0, 0, 0, 0);
  z-index: 4;
  box-sizing: border-box;
  .body-re {
    @include position(absolute, 20px, 20px, 20px, 20px);
    @include scrollY();
    @include scrollX();
  }
  table {
    border-color: #055074;
    .title {
      th {
        min-width: 200px;
        height: 50px;
        border: 2px solid #055074;
      }
    }
    .content {
      td {
        min-width: 200px;
        height: 50px;
        text-align: center;
        border: 2px solid #055074;
      }
    }
  }
  // .table{
  //   @include scrollX();
  //   .title{
  //     display: flex;
  //     border: 1px solid #055074;
  //     border-bottom: none;
  //     li{
  //       min-width: 200px;
  //       height: 50px;
  //       border: 1px solid #055074;
  //       text-align: center;
  //       line-height: 50px;
  //     }
  //   }
  //   .content{
  //     height: calc(100vh - 170px);
  //     width: 100%;
  //     position: relative;
  //     ul{
  //       position: absolute;
  //       top: 0;
  //       bottom: 0;
  //       @include scrollY();

  //     }
  //     li{
  //       display: flex;
  //       >div{
  //         min-width: 200px;
  //         height: 50px;
  //         text-align: center;
  //         line-height: 50px;
  //         border: 1px solid #055074;
  //         @include textOverflowHidden();
  //       }
  //     }
  //   }
  // }
  .no-thing {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #999;
    font-size: 20px;
  }
}
</style>
