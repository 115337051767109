import carIcon from '@/assets/images/model/car.png'

export default {
  created() {
    this.outSideData = []
    this.entityList = []
  },

  methods: {
    //绘制外景数据
    drawHandlerData(dirType) {
      //显示外景/隐藏内景
      this.$EventBus.$emit('change.ceter.map.status', false)

      this.$EventBus.$emit('control.cesium.model.paint', {
        data: this.outSideData,
        callback: (data) => {
          this.entityList.push(data.primitive)
        }
      })

      //当存在记录的位置时跳到相应的位置
      const { position } = this.currentData
      if (position) {
        return this.$refs.centerMap.handlerFlyToDefault(JSON.parse(position))
      }

      //跳转到一个集合的视角
      if (+dirType === 2 || +dirType === 4) {
        this.flyToFireGather()
      }
      // this.$EventBus.$emit('control.cesium.mode.fly.default')
    },

    // 处理车辆数据
    formatOutsideDrawDataByCar(item) {
      let config = {}

      let positions = item.position
      let { jd, wd } = item

      if (positions && typeof positions === 'string') {
        positions = JSON.parse(positions)
      } else if (jd && wd) {
        positions = Cesium.Cartesian3.fromDegrees(jd, wd, -0.5)
      }

      if (item.carId) {
        config.drawType = 'pointModel'
        config.options = {
          color: '#fff',
          pointIcon: carIcon,
          pointIconWidth: 32,
          pointIconHeight: 32,
          backgroundColor: '#3a93da',
          scale: this.getCarScale(item.modelUrl)
        }
      }

      return {
        id: item.carId,
        drawName: item.carName,
        config: JSON.stringify({ positions }),
        drawTool: {
          icon: carIcon,
          id: item.organId,
          config: JSON.stringify(config)
        },
        modelUrl: item.modelUrl,
        // 保留一份原始数据
        origin: item
      }
    },

    // 外景的数据不是标准化的，需要另外处理一遍
    formatOutsideDrawData(item, itemKey) {
      let config = {}
      try {
        config = JSON.parse(item.config)
      } catch (error) {
        /* error */
      }

      // 添加描述
      if (config.options) {
        config.options = {
          ...config.options,
          description: item.htmlDesc
        }
      } else {
        config.options = {
          description: item.htmlDesc
        }
      }
      if (+item.deployTypeId === 55) {
        config.drawType = 'wallGeometry'
      }
      return {
        itemKey, // 储存起来，删除的时候用来判断删除的类型
        id: item.id,
        drawName: item.deployType.name,

        config: JSON.stringify({
          positions: JSON.parse(item.position)
        }),

        drawTool: {
          id: item.deployType.taskId,
          config: JSON.stringify(config)
        },
        // 保留一份原始数据
        origin: item
      }
    },

    //清除外景数据
    clearOutSideData() {
      this.entityList.forEach(item => item.remove())
      this.outSideData = []
    },

    //相机飞到实体集合视角
    flyToFireGather() {
      let positionList = []
      this.outSideData.forEach((item) => {
        const { positions } = JSON.parse(item.config)
        //有时间的话可以把这里的面多点的情况改为取中心点
        if (Array.isArray(positions)) {
          positionList.push(...positions)
          return
        }
        positionList.push(positions)
      })

      if (!positionList.length) return
      this.$refs.centerMap.cesiumBox().handlerFlyToAssemble(positionList)
    },

    getCarScale(modelUrl) {
      // 枚举车模型,根据不同车型设置相应的显示倍数
      const modeList = [
        { modelUrl: 'climb', scale: 0.05 }, //登高车
        { modelUrl: 'foam', scale: 0.012 }, //泡沫车
        { modelUrl: 'high_spray', scale: 0.01 }, //高喷车
        { modelUrl: 'water', scale: 0.07 }, //水罐车
        { modelUrl: 'main_station', scale: 0.035 }, //主战车
        { modelUrl: 'jug', scale: 0.03 }, //水罐车1
        { modelUrl: 'jh', scale: 0.01 } //救护车
      ]

      const result = modeList.find(item => new RegExp(`${item.modelUrl}_car.gltf$`).test(modelUrl))

      return result?.scale || 0.015
    },

    //存储当前相机位置,偏移角度
    saveAddress() {
      const positionData = this.$refs.centerMap.getCameraPosition()
      this.currentData.position = JSON.stringify(positionData)
    }
  }
}
