<template>
  <div class="deduce-data">
    <ul v-if="list.length">
      <li v-for="(item, index) in list" :key="index">
        <el-image
          style="width: 191px; height: 133px"
          :src="item.imageUrl"
          :preview-src-list="
            list.map(item => {
              return item.imageUrl
            })
          "
        />
        <span>{{ formatDateAndTime(item.gmtCreate) }}</span>
      </li>
    </ul>

    <el-empty v-else style="margin-top: 100px" :image-size="60" description="暂无推演资料" />
  </div>
</template>

<script>
import { getWhiteBoardList } from '@/api/checkPlan'
import { formatDateAndTime } from '@/utils/util'
export default {
  data() {
    return {
      formatDateAndTime,
      list: []
    }
  },

  created() {
    getWhiteBoardList({ sandTableId: this.$route.query.id }).then((data) => {
      this.list = data
      console.log(data)
    })
  }
}
</script>

<style scoped lang="scss">
@import '../common.scss';
.deduce-data {
  @include position(absolute, 0, 0, 0, 0);
  z-index: 4;
  background-color: #061121;
  padding: 0 21px 24px;
  box-sizing: border-box;
  > ul {
    display: flex;
    flex-wrap: wrap;
    max-height: 100%;
    @include scrollY();
    min-width: 1100px;
    > li {
      width: 207px;
      height: 172px;
      background-color: #0a2543;
      margin: 0 7px 18px;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      padding-top: 8px;
      > img {
        width: 191px;
        height: 133px;
      }
      > span {
        margin-top: 10px;
        font-size: 12px;
      }
    }
  }
}
</style>
