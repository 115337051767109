<template>
  <div class="title-hint">
    <div class="hint-top" v-if="+currentData.dirType === 2">
      <p>
        <i>灾情描述：</i>
        <span v-for="(item, index) in fireData" :key="index">
          室外:火
          {{ item.buildId > 0 ? item.modelKey || '室内' : '室外' }}
          {{ `${item.direction ? item.direction + '侧-' : ''}${item.htmlDesc || '发生火灾'}` }}；
        </span>
        <span v-if="!this.fireData.length">无</span>
      </p>
    </div>
    <!-- <div class="input-textarea" v-if="+currentData.dirType !== 5">
      <img src="@/assets/images/checkPlan/shuRuIcon.png" alt="" />
      <el-input
        :class="{ 'is-border': isBorder }"
        type="textarea"
        placeholder="点击输入描述或说明（200字内）"
        v-model="textarea"
        maxlength="200"
        show-word-limit
        resize="none"
        :autosize="{ minRows: 1, maxRows: 2 }"
        @input="saveContent"
        @focus="isBorder = true"
        @blur="isBorder = false"
      />
    </div> -->
    <div class="title-bar" v-if="+currentData.dirType === 5">推演资料</div>
  </div>
</template>

<script>
export default {
  props: {
    currentData: Object,
    fireData: {
      type: Array,
      default() {
        return []
      }
    }
  },

  data() {
    return {
      textarea: '',
      isBorder: false
    }
  },

  methods: {
    setValue(str) {
      this.textarea = str || ''
    },

    //保存描述
    saveContent(content) {
      clearTimeout(this.timeId)
      this.timeId = setTimeout(() => {
        this.$parent.saveDataFn('content', content)
      }, 500)
    }
  }
}
</script>

<style scoped lang="scss">
.title-hint {
  position: relative;
  z-index: 2;
  width: 100%;
  background-color: #020f21;
  box-sizing: border-box;
  .hint-top {
    padding: 12px;
    i {
      font-style: normal;
      color: #18cffd;
    }
  }
  .input-textarea {
    position: relative;
    img {
      width: 24px;
      height: 24px;
      position: absolute;
      left: 11px;
      top: 4px;
    }
    .el-textarea {
      width: 100%;
      /deep/.el-textarea__inner {
        border: none;
        box-shadow: unset;
        background-color: unset;
        padding-left: 36px;
        box-sizing: border-box;
        &::placeholder {
          color: #4d5868;
        }
        &:hover {
          outline: 1px solid #1a3151;
        }
      }
    }
  }
  .is-border {
    outline: 1px solid #1a3151;
  }
  .title-bar {
    font-size: 16px;
    display: flex;
    margin: 20px 25px;
  }
}
</style>
