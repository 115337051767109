<template>
  <nav class="check-nav-bar">
    <div class="body-content">
      <div class="nav-left">
        <i class="iconfont iconback" @click.stop="$router.back()"> 返回</i>
      </div>
      <div class="nav-center">
        <span>推演名</span>
      </div>
      <div class="nav-right"></div>
    </div>
  </nav>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
@import '../common';
.check-nav-bar {
  @include position(absolute, 0, 0, 0);
  color: #18cffd;
  z-index: 2;
  background: #061326;
  .body-content {
    background: linear-gradient(0deg, rgba(6, 19, 38, 0.53), rgba(17, 49, 94, 0.53));
    padding: 0 20px;
    @include flex(space-between);
    height: 48px;
    .nav-left i {
      font-size: 16px;
      cursor: pointer;
      &:hover {
        color: #026ddc;
      }
    }
    .nav-center span {
      font-size: 18px;
    }
  }
}
</style>
