import { GET, POST } from '@/utils/http'

const SERVE_HOST = '/service-fire'

//获取PPT列表
export const getPptList = (params) => {
  return GET({
    url: `${SERVE_HOST}/fire/sandTable/ppt/list`,
    params
  })
}

//编辑PPT
export const editPptPic = (params) => {
  return POST({
    url: `${SERVE_HOST}/fire/sandTable/ppt/addPic`,
    params
  })
}

//获取部署阶段数据
export const getDeployList = (params) => {
  return GET({
    url: `${SERVE_HOST}/fire/sandTable/deploy/list`,
    params
  })
}

//获取沙盘记录列表
export const getWhiteBoardList = (params) => {
  return GET({
    url: `${SERVE_HOST}/fire/sandTable/whiteBoard/list`,
    params
  })
}

//获取力量调派车辆列表
export const getOverviewList = (params) => {
  return GET({
    url: `${SERVE_HOST}/fire/sandTable/organ/overview`,
    params
  })
}
